.activeStep {
  cursor: pointer;
  transition: all 300ms ease;
  width: 104px;
  height: 4px;
  background-color: white;
  border-radius: 6px;
}

.inactiveStep {
  cursor: pointer;
  transition: all 300ms ease;
  width: 104px;
  height: 4px;
  background-color: rgba($color: #ffffff, $alpha: 0.2);
  border-radius: 6px;
}
