@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* Style for the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* color of the track */
}

/* Style for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: lightgray; /* color of the thumb */
  border-radius: 4px; /* roundness of the thumb */
}
